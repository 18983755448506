import addMeta from '@/router/addMeta';

const children = [
  {
    path: 'management',
    name: 'customer-management',
    component: () => import('@/pages/customer/CustomerManagement.vue'),
  },
  {
    path: 'record',
    name: 'customer-record',
    component: () => import('@/pages/customer/CustomerRecord.vue'),
  },
];
const routes = {
  path: '/customer',
  name: 'customer',
  redirect: '/customer',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'customer'),
};

export default routes;
export { children };
