import addMeta from '@/router/addMeta';

const children = [
  {
    path: 'login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: 'change-password',
    name: 'change-password',
    component: () => import('@/pages/ChangePassword.vue'),
  },
  {
    path: 'home',
    name: 'home',
    component: () => import('@/pages/Home.vue'),
  },
];
const routes = {
  path: '/',
  name: 'base',
  redirect: '/login',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'base'),
};

export default routes;
export { children };
