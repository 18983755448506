// 先封裝起來，若有其他操作需求，可直接新增
interface ICusStorage {
  prefix: string
  setItem: (key: string, val: any) => void
  getItem: (key: string) => any
  removeItem: (key: string) => void
  clear: () => void
}

const cusStorage: ICusStorage = {
  prefix: 'hw_crm_',
  setItem(key, val) {
    const jsonVal: string = JSON.stringify(val);
    localStorage.setItem(`${this.prefix}${key}`, jsonVal);
  },
  getItem(key) {
    const parseVal: string = localStorage.getItem(`${this.prefix}${key}`) || '';

    return parseVal !== '' ? JSON.parse(parseVal) : null;
  },
  removeItem(key) {
    localStorage.removeItem(`${this.prefix}${key}`);
  },
  clear() {
    localStorage.clear();
  },
};

export default cusStorage;
