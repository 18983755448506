import addMeta from '@/router/addMeta';

const children = [
  {
    path: 'report',
    name: 'statistics-report',
    component: () => import('@/pages/statistics/Report.vue'),
  },
  {
    path: 'download',
    name: 'statistics-download',
    component: () => import('@/pages/statistics/Download.vue'),
  },
];
const routes = {
  path: '/statistics',
  name: 'statistics',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'statistics'),
};

export default routes;
export { children };
