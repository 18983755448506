import addMeta from '@/router/addMeta';

const children = [
  {
    path: 'list',
    name: 'survey-list',
    component: () => import('@/pages/survey/SurveyList.vue'),
  },
];
const routes = {
  path: '/survey',
  name: 'survey',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'survey'),
};

export default routes;
export { children };
