import { createRouter, createWebHistory } from 'vue-router';
import base from '@/router/pages/base';
import survey from '@/router/pages/survey';
import customer from '@/router/pages/customer';
import statistics from '@/router/pages/statistics';
import cusStorage from '@/utils/cusStorage';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [base, survey, customer, statistics],
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    const loginStatus = cusStorage.getItem('isLogin');

    if (!loginStatus) {
      if (from.name === 'login') {
        next(false);
      }
      else {
        next({
          name: 'login',
        });
      }

      return;
    }
  }

  const scrollEle = document.querySelector('.ly-base-layout__body__container__content');

  if (scrollEle) {
    scrollEle.scrollTo({
      top: 0,
      left: 0,
    });
  }

  next();
});

export default router;
